import { json } from '@remix-run/cloudflare';
import { Outlet, useLoaderData, useMatches } from '@remix-run/react';
import { useMemo } from 'react';

import { Heading } from '-/aslan/components/Heading/Heading';
import Text from '-/aslan/components/Text/Text';
import { useT } from '-/i18n/useI18n';
import { getSession, storeCreatorLoggedIn } from '-/leap-auth/sessions';
import { outreachCookie, readOutreachCookie } from '-/leap-auth/cookies';
import { ThemeStyles } from '-/leap-pages/src/components/ThemeStyles';
import { fontLinks } from '-/leap-pages/src/routes/links';
import { BrandProvider } from '-/leap-pages/src/providers/BrandProvider';
import { ThemeTheLeap } from '-/leap-pages/src/models/themes';
import { customColorTheme } from '-/leap-pages/src/hooks/useTheme';
import { Layouts } from '-/leap-pages/src/components/Layouts';
import { notFoundError } from '-/utils/errors';
import { FeaturesProvider } from '~/providers/FeaturesProvider';
import { creatorMetaTags } from '~/storefront/helpers/metaTags';
import { getFeatures } from '~/storefront/services/features.server';
import { getStylesData } from '~/storefront/services/site.server';

import type {
  LinksFunction,
  LoaderFunctionArgs,
  LoaderFunction,
  MetaFunction } from
'@remix-run/cloudflare';
import type { LeapFeature } from '-/leap-models';

export const links: LinksFunction = fontLinks;

export const loader: LoaderFunction = async (args: LoaderFunctionArgs) => {
  try {
    const { session } = await getSession(args);
    const handle = args.params.handle;
    const { brand, status } = await getStylesData(args);
    const features: LeapFeature[] = await getFeatures(args, handle);
    const isCreatorLoggedIn = handle ?
    storeCreatorLoggedIn(session, handle) :
    false;

    const outreachData = await readOutreachCookie(args);
    const previewToken =
    outreachData.previewToken ||
    new URL(args.request.url).searchParams.get('previewToken');
    const isPreviewTokenValid = !!previewToken; // TODO: validate token
    if (status === 'OUTREACH_PREVIEW' && !isPreviewTokenValid) {
      throw notFoundError();
    }

    return json(
      {
        brand,
        status,
        isCreatorLoggedIn,
        features
      },
      {
        ...(status === 'DEACTIVATED' ? { status: 410 } : {}),
        ...(previewToken ?
        {
          headers: {
            'Set-Cookie': await outreachCookie(args.context).serialize({
              previewToken
            })
          }
        } :
        {})
      }
    );
  } catch (e) {
    console.error(e);
    throw notFoundError();
  }
};

export const meta: MetaFunction<CreatorStoreRootData> = ({ params, data }) => {
  const typedData = (data as CreatorStoreRootData);

  const handle = (params.handle as string);
  const title = `${typedData.brand.name} @ TheLeap`;
  const description = `${typedData.brand.name}'s creator store on The Leap`;
  const color = typedData.brand.colors.background;
  const imageUrl = typedData.brand.images.profileImageUrl;
  const noIndex = true;

  return creatorMetaTags(handle, title, description, color, imageUrl, noIndex);
};

export interface CreatorStoreRootData {
  brand: any;
  status: string;
  isCreatorLoggedIn: boolean;
  features: LeapFeature[];
}

export function useCreatorStoreRootData() {
  const matches = useMatches();
  const routeMatch = matches.find(
    (match) => match.id === 'routes/creator.$handle'
  );
  return (routeMatch?.data as CreatorStoreRootData);
}

export default function Store() {
  const { brand, status, isCreatorLoggedIn, features } =
  useLoaderData<CreatorStoreRootData>();
  const t = useT(
    'translation',
    `myStore.status.${isCreatorLoggedIn ? 'creator' : 'anonymous'}.${status}`
  );

  const theme = useMemo(() => {
    if (brand.theme) return brand.theme;
    if (brand.colors?.background) {
      return customColorTheme(brand.colors.background);
    }
    return ThemeTheLeap;
  }, [brand]);

  if (status === 'DEACTIVATED') {
    return (
      <Layouts.Error>
        <Heading as="h1" size="3xl" className="font-serif">
          {t('title')}
        </Heading>
        <Text size="lg">
          {t('description')}
          <a
            className="underline hover:no-underline"
            href="https://www.theleap.co/the-leap-content-guidelines/"
            target="_blank"
            rel="noreferrer">

            {' '}
            {t('contentPolicies')}
          </a>
        </Text>
        <Text size="lg">{t('guidingPrinciples')}</Text>
        {isCreatorLoggedIn &&
        <Text size="lg">
            {t('support')}{' '}
            <a
            className="underline hover:no-underline"
            href="mailto:beta@theleap.co">

              {t('contactUs')}
            </a>
          </Text>}

      </Layouts.Error>);

  }

  return (
    <>
      <FeaturesProvider features={features}>
        <ThemeStyles
          cssVariables={theme.cssVariables}
          coverImageUrl={brand.images?.coverImageUrl}
          profileImageUrl={brand.images?.profileImageUrl}
          presetName={theme.parameters.preset} />

        <BrandProvider brand={brand}>
          <Outlet />
        </BrandProvider>
      </FeaturesProvider>
    </>);

}